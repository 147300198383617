import React from 'react'
import {NavLink} from 'react-router-dom'
const TopNav = ({ updateUser }) => {
 
  return (
    <header className="header">
        <nav className="navbar">
          <div className="container-fluid">
            <div className="d-flex align-items-center justify-content-between w-100">
              <div className="d-flex align-items-center"><a className="menu-btn d-flex align-items-center justify-content-center p-2 bg-gray-900" id="toggle-btn" href="#">
                  <svg className="svg-icon svg-icon-sm svg-icon-heavy text-white">
                    <use xlinkHref="#menu-1"> </use>
                  </svg></a><NavLink className="navbar-brand ms-2" to="">
                  <div className="brand-text d-none d-md-inline-block text-uppercase letter-spacing-0"><strong className="text-success text-sm">Bulk WhatsApp</strong></div></NavLink></div>
              <ul className="nav-menu mb-0 list-unstyled d-flex flex-md-row align-items-md-center">
                {/* <!-- Notifications dropdown--> */}
                <li className="nav-item dropdown"> <a className="nav-link text-white position-relative" id="notifications" rel="nofollow" data-bs-target="#" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"/>
                  
                  <ul className="dropdown-menu dropdown-menu-end mt-sm-3 shadow-sm" aria-labelledby="notifications">
                    <li><a className="dropdown-item py-3" href="#!"> 
                        <div className="d-flex">
                          <div className="icon icon-sm bg-blue">
                            <svg className="svg-icon svg-icon-xs svg-icon-heavy">
                              <use xlinkHref="#envelope-1"> </use>
                            </svg>
                          </div>
                          <div className="ms-3"><span className="h6 d-block fw-normal mb-1 text-xs text-gray-600">You have 6 new messages </span><small className="small text-gray-600">4 minutes ago</small></div>
                        </div></a></li>
                    <li><a className="dropdown-item py-3" href="#!"> 
                        <div className="d-flex">
                          <div className="icon icon-sm bg-green">
                            <svg className="svg-icon svg-icon-xs svg-icon-heavy">
                              <use xlinkHref="#chats-1"> </use>
                            </svg>
                          </div>
                          <div className="ms-3"><span className="h6 d-block fw-normal mb-1 text-xs text-gray-600">New 2 WhatsApp messages</span><small className="small text-gray-600">4 minutes ago</small></div>
                        </div></a></li>
                    <li><a className="dropdown-item py-3" href="#!"> 
                        <div className="d-flex">
                          <div className="icon icon-sm bg-orange">
                            <svg className="svg-icon svg-icon-xs svg-icon-heavy">
                              <use xlinkHref="#checked-window-1"> </use>
                            </svg>
                          </div>
                          <div className="ms-3"><span className="h6 d-block fw-normal mb-1 text-xs text-gray-600">Server Rebooted</span><small className="small text-gray-600">8 minutes ago</small></div>
                        </div></a></li>
                    <li><a className="dropdown-item py-3" href="#!"> 
                        <div className="d-flex">
                          <div className="icon icon-sm bg-green">
                            <svg className="svg-icon svg-icon-xs svg-icon-heavy">
                              <use xlinkHref="#chats-1"> </use>
                            </svg>
                          </div>
                          <div className="ms-3"><span className="h6 d-block fw-normal mb-1 text-xs text-gray-600">New 2 WhatsApp messages</span><small className="small text-gray-600">10 minutes ago</small></div>
                        </div></a></li>
                    <li><a className="dropdown-item all-notifications text-center" href="#!"> <strong className="text-xs text-gray-600">view all notifications                                            </strong></a></li>
                  </ul>
                </li>
                {/* <!-- Messages dropdown--> */}
                <li className="nav-item dropdown"> <a className="nav-link text-white position-relative" id="messages" rel="nofollow" data-bs-target="#" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                    </a>
                  <ul className="dropdown-menu dropdown-menu-end mt-sm-3 shadow-sm" aria-labelledby="messages">
                    <li><a className="dropdown-item d-flex py-3" href="#!"> <img className="img-fluid rounded-circle flex-shrink-0 avatar shadow-0" src="img/avatar-1.jpg" alt="..." width="45"/>
                        <div className="ms-3"><span className="h6 d-block fw-normal mb-1 text-sm text-gray-600">Jason Doe</span><small className="small text-gray-600"> Sent You Message</small>
                          <p className="mb-0 small text-gray-600">3 days ago at 7:58 pm - 10.06.2014</p>
                        </div></a></li>
                    <li><a className="dropdown-item d-flex py-3" href="#!"> <img className="img-fluid rounded-circle flex-shrink-0 avatar shadow-0" src="img/avatar-2.jpg" alt="..." width="45"/>
                        <div className="ms-3"><span className="h6 d-block fw-normal mb-1 text-sm text-gray-600">Jason Doe</span><small className="small text-gray-600"> Sent You Message</small>
                          <p className="mb-0 small text-gray-600">3 days ago at 7:58 pm - 10.06.2014</p>
                        </div></a></li>
                    <li><a className="dropdown-item d-flex py-3" href="#!"> <img className="img-fluid rounded-circle flex-shrink-0 avatar shadow-0" src="img/avatar-3.jpg" alt="..." width="45"/>
                        <div className="ms-3"><span className="h6 d-block fw-normal mb-1 text-sm text-gray-600">Jason Doe</span><small className="small text-gray-600"> Sent You Message</small>
                          <p className="mb-0 small text-gray-600">3 days ago at 7:58 pm - 10.06.2014</p>
                        </div></a></li>
                    <li><a className="dropdown-item text-center" href="#!"> <strong className="text-xs text-gray-600">Read all messages       </strong></a></li>
                  </ul>
                </li>
                {/* <!-- Languages dropdown    --> */}
                {/* <li className="nav-item dropdown"><a className="nav-link dropdown-toggle text-white text-sm" id="languages" rel="nofollow" data-bs-target="#" href="#" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><img src="img/flags/16/GB.png" alt="English"/><span className="d-none d-sm-inline-block ms-2">English</span></a>
                  <ul className="dropdown-menu dropdown-menu-end mt-sm-3 shadow-sm" aria-labelledby="languages">
                    <li><a className="dropdown-item" rel="nofollow" href="#!"> <img className="me-2" src="img/flags/16/DE.png" alt="English"/><span>German</span></a></li>
                    <li><a className="dropdown-item" rel="nofollow" href="#!"> <img className="me-2" src="img/flags/16/FR.png" alt="English"/><span>French                                                         </span></a></li>
                  </ul>
                </li> */}
                {/* <!-- Log out--> */}
                <li  className="nav-item"><NavLink onClick={()=>updateUser({})} className="nav-link text-white text-sm ps-0" to="/login"> <span className="d-none d-sm-inline-block">Logout</span>
                    <svg className="svg-icon svg-icon-xs svg-icon-heavy">
                      <use xlinkHref="#security-1"> </use>
                    </svg></NavLink></li>
              </ul>
            </div>
          </div>
        </nav>
      </header>
  )
}

export default TopNav