import React, { useState } from 'react'
import { NavLink, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
import axios from 'axios'
const Login = ({ updateUser }) => {
  const navigate = useNavigate();
  // console.log(navigate)
  const [loginUser, setUser] = useState({
    email: "",
    password: ""
  })
  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...loginUser, [name]: value
    })
  }
  const handleLogin = async (e) => {
    e.preventDefault();
    const { email, password } = loginUser;
    if (email && password) {
      await axios.post("https://apibulkwhatsapp.redesk.in/login", loginUser)
        .then((res) => {
          //  console.log(res)
          if (res.data.status === "200") {
            toast.success(res.data.msg, {
              position: "top-right",
              theme: "colored",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,

            });

            //  console.log(res.data)
            updateUser(res.data.user);
            navigate('/')
          } else {
            toast.error(res.data.msg, {
              position: "top-right",
              theme: "colored",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,

            });
          }
        }).catch(err => {
          if (err) {
            toast.error("Invalid Credential", {
              position: "top-right",
              theme: "colored",
              autoClose: 5000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,

            });
          }
        })
    } else {
      toast.error("Invalid Inputs", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }
  return (
    <div className="login-page d-flex align-items-center bg-gray-100">
      <ToastContainer />
      <div className="container mb-3">
        <div className="row">
          <div className="col-md-6 mx-auto">
            <div className="card">
              <div className="card-body p-5">
                <header className="text-center mb-5">
                  <h1 className="text-xxl text-gray-400 text-uppercase">Bulk<strong className="text-primary">WhatsApp</strong></h1>
                  {/* <p className="text-gray-500 fw-light">Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p> */}
                </header>
                <form className="login-form" method="get" action="index.html">
                  <div className="row">
                    <div className="col-lg-7 mx-auto">
                      <div className="input-material-group mb-3">
                        <input className="input-material" id="login-username" placeholder="Email" type="email" name="email" autoComplete="off" required data-validate-field="email" onChange={handleChange} value={loginUser.email} />
                        {/* <label className="label-material" for="login-username">Username</label> */}
                      </div>
                      <div className="input-material-group mb-4">
                        <input className="input-material" id="login-password" type="password" placeholder="Password" name="password" required data-validate-field="password" onChange={handleChange} value={loginUser.password} />

                      </div>
                    </div>
                    <div className="col-12 text-center">
                      <button onClick={handleLogin} className="btn btn-primary mb-3" id="login" type="submit">Login</button><br /><span className="text-xs mb-0 text-gray-500">Do not have an account?  </span><NavLink className="text-xs text-paleBlue ms-1" to="/register"> Signup</NavLink>

                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center position-absolute bottom-0 start-0 w-100 z-index-20">
        <p className="text-gray-500">Design by <a className="external" href="https://bootstrapious.com/p/admin-template">RechargeKit</a>
        </p>
      </div>
    </div>
  )
}

export default Login