import './App.css';
import { useEffect, useState } from 'react'
import QrCode from './components/QrCode';
import SendBulkMessage from './components/SendBulkMessage';
import Login from './components/Login';
import Register from './components/Register';
import TopNav from './components/TopNav';
import HomePage from './components/HomePage';
import io from "socket.io-client";
import { Routes, Route, useNavigate } from "react-router-dom"

const ENDPOINT = "https://apibulkwhatsapp.redesk.in/";
function App() {

  const navigate = useNavigate();
  const [user, setLoginUser] = useState({});
  useEffect(() => {
    setLoginUser(JSON.parse(localStorage.getItem("multiple_whatsapp")));
  }, []);

  const updateUser = (user) => {
    localStorage.setItem("multiple_whatsapp", JSON.stringify(user))
    setLoginUser(user)
  }

  const [response, setResponse] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const socket = io.connect(ENDPOINT);
    socket.on('init', data => {
      // console.log(data)
    });
    socket.off('qr').on('qr', data => {
      setResponse(data.src);
      console.log(data.src)
    });
    socket.off('message').on("message", val => {
      console.log(val.text)
      setMessage(val.text);
      if (val.text === "Whatsapp is ready!") {
        navigate("/bulk_sms");
        // socket.close();
        // socket.removeAllListeners();
      }
      if (val.text === "Whatsapp is disconnected!") {
        navigate("/qr");
      }
    });
    // socket.on("ready", val => {
    //   console.log(val);

    // });
  }, [navigate]);
  return (
    <div className="App">

      <Routes>
        <Route path="/login" element={<Login updateUser={updateUser} />} />
        <Route path="/qr" element={user && user.user_id ? <QrCode updateUser={updateUser} message={message} response={response} /> : <Login updateUser={updateUser} />} />
        <Route exact path="/" element={user && user.user_id ? <HomePage updateUser={updateUser} /> : <Login updateUser={updateUser} />} />
        <Route path="/bulk_sms" element={user && user.user_id ? <SendBulkMessage updateUser={updateUser} QrMessage={message} /> : <Login updateUser={updateUser} />} />
        <Route element={
          user && user.user_id ? <TopNav updateUser={updateUser} /> : <Login updateUser={updateUser} />} />

        <Route path="/register" element={<Register />} />
      </Routes>

    </div>
  );
}

export default App;
