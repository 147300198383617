import React, { useState } from "react";
import SideNav from "./SideNav";
import TopNav from "./TopNav";
// import socketIOClient from "socket.io-client";
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import axios from 'axios'
import { useNavigate } from "react-router-dom";
const SendBulkMessage = ({ updateUser }) => {
  const userName = JSON.parse(localStorage.getItem("multiple_whatsapp"));
  const navigate = useNavigate();
  const [value, setValue] = useState('');
  const handleValue = (e) => {
    setValue(e.target.value);
    // console.log(value)
  }
  const [imageValue, setImageValue] = useState('');
  const handleImageValue = (e) => {
    setImageValue(e.target.value);
    // console.log(imageValue);
  }
  // const [mobile, setMobile] = useState("");
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [message, setMessage] = useState("");
  // console.log(file)
  const handleFiles = (e) => {
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name)
  }
  const [loading, setLoading] = useState(false);
  const [multiLoading, setMultiLoading] = useState(false);
  const handleMultipleSms = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileName", fileName);
    const msg = message
    if (msg) {
      setMultiLoading(true)
      try {
        const res = await axios({
          method: "post",
          url: "https://apibulkwhatsapp.redesk.in/multi-message",
          data: {
            file: file,
            "sender": userName.mobile,
            message: msg
          },
          headers: { "Content-Type": "multipart/form-data" },
        });
        console.log(res)
        if (res.status === 200) {
          setMultiLoading();
          toast.success("Message Send Successfully", {
            position: "bottom-right",
            theme: "colored",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setMessage("")

        } else {
          setMultiLoading();
          toast.error("Message Send failed", {
            position: "bottom-right",
            theme: "colored",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(() => {
            navigate("/qr");
          }, 2000);
        }

      } catch (e) {
        setMultiLoading();
        if (e.response.data.status === false) {
          toast.error(e.response.data.message, {
            position: "bottom-right",
            theme: "colored",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    } else {
      toast.error("Some fields are missing", {
        position: "bottom-right",
        theme: "colored",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  const [inputMessage, setInputMessage] = useState({
    number: "",
    message: ""
  })
  const handleMessage = (e) => {

    const { name, value } = e.target;
    setInputMessage({
      ...inputMessage, [name]: value
    })
  }
  const handleSendMessage = async (e) => {
    e.preventDefault();

    const { number, message } = inputMessage;
    if (number && message && number.length === 10) {


      const res = await fetch('https://apibulkwhatsapp.redesk.in/send-message', {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json"
        },
        body: JSON.stringify({ "sender": userName.mobile, number, message })
      })
      const data = await res.json();
      console.log(data);
      if (data.status === true) {

        toast.success("Message send successfully", {
          position: "bottom-right",
          theme: "colored",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        setInputMessage({
          number: "",
          message: ""
        })
      } else {

        toast.error("Message send failed scan again", {
          position: "bottom-right",
          theme: "colored",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });

        setInputMessage({
          number: "",
          message: ""
        })
        setTimeout(() => {
          navigate("/qr");
        }, 2000);
      }
    } else {
      toast.error("Invalid Credential", {
        position: "bottom-right",
        theme: "colored",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }

  }
  const [image, setImage] = useState(null);
  const [imageName, setImageName] = useState("");
  const handleUploadImages = (e) => {
    setImage(e.target.files[0]);
    setImageName(e.target.files[0].name)
    // console.log(image)
  }

  const handleMediaMessage = async (e) => {
    e.preventDefault();
    const { number, message } = inputMessage;
    if (number && message && number.length === 10) {
      setLoading(true);
      const formData = new FormData();
      formData.append("image", image);
      formData.append("Image_Name", imageName);
      try {
        const res = await axios({
          method: "post",
          url: "https://apibulkwhatsapp.redesk.in/send-media",
          data: {
            "file": image,
            "sender": userName.mobile,
            "number": number,
            "caption": message
          },
          headers: { "Content-Type": "multipart/form-data" },
        });
        if (res.status === 200) {
          setLoading();
          toast.success("message send Successfully", {
            position: "bottom-right",
            theme: "colored",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });


        } else {
          setLoading();
          toast.error("Message Send failed", {
            position: "bottom-right",
            theme: "colored",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(() => {
            navigate("/qr");
          }, 2000);
        }

      } catch (e) {
        setLoading();
        if (e.response.data.status === false) {
          toast.error(e.response.data.message, {
            position: "bottom-right",
            theme: "colored",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    } else {
      toast.error("Invalid Credential", {
        position: "bottom-right",
        theme: "colored",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }
  const handleMultipleMediaSms = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("image", image);
    formData.append("Image_Name", imageName);
    formData.append("file", file);
    formData.append("fileName", fileName);
    const msg = message
    if (msg) {
      setMultiLoading(true);
      try {
        const res = await axios({
          method: "post",
          url: "https://apibulkwhatsapp.redesk.in/multi-media-message",
          data: {
            "file1": file,
            "file": image,
            "sender": userName.mobile,
            "caption": msg
          },
          headers: { "Content-Type": "multipart/form-data" },
        });
        console.log(res)
        if (res.status === 200) {
          setMultiLoading();
          toast.success("message send Successfully", {
            position: "bottom-right",
            theme: "colored",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });


        } else {
          setMultiLoading();
          toast.error("Message Send failed", {
            position: "bottom-right",
            theme: "colored",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
          setTimeout(() => {
            navigate("/qr");
          }, 2000);
        }

      } catch (e) {
        setMultiLoading()
        if (e.response.data.status === false) {
          toast.error(e.response.data.message, {
            position: "bottom-right",
            theme: "colored",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          });
        }
      }
    } else {
      toast.error("Some fields are missing", {
        position: "bottom-right",
        theme: "colored",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  }

  return (
    <div>
      <SideNav />

      <div className="page">

        <TopNav updateUser={updateUser} />
        <ToastContainer />
        <section className="bg-white py-5">

          <div className="container-fluid">

            <div className="row d-flex align-items-md-stretch">

              <div className="col-md-6">
                <h3 className="mb-5"><i className="fas fa-hand-point-right"></i> Multiple Numbers</h3>
                <div className=" shadow-0">
                  <div className="-body p-0">
                    <form className="form-horizontal" method="POST">

                      <div className="row gy-2 mb-4">
                        <label className="col-sm-3 form-label" htmlFor="inputHorizontalElOne">Choose sms send type</label>
                        <div className="col-sm-9">
                          <select onClick={handleValue} className="custom-select custom-select-lg mb-3" >
                            <option defaultValue>Choose sms type</option>
                            <option value="1" >Send only message</option>
                            <option value="2">Send message with image</option>
                          </select>
                        </div>
                      </div>
                      {
                        value === "1" && (
                          <>
                            <div className="row gy-2 mb-4">
                              <label className="col-sm-3 form-label" htmlFor="inputHorizontalElOne">Upload File</label>
                              <div className="col-sm-9">
                                <input className="form-control" id="inputHorizontalElOne" type="file" name="number" placeholder="Enter Number" onChange={handleFiles} required /><small className="form-text">Upload Your .csv File</small>
                                <a href="./demo.csv">Download Sample</a>
                              </div>
                            </div>
                            <div className="row gy-2 mb-4">
                              <label className="col-sm-3 form-label" htmlFor="inputHorizontalElTwo">Enter Message</label>
                              <div className="col-sm-9">
                                <textarea className="form-control" name="message" rows="4" cols="50" onChange={(e) => setMessage(e.target.value)} value={message} required></textarea><small className="form-text">Example help text that remains unchanged.</small>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-9 ms-auto ">
                                <button onClick={handleMultipleSms} className="btn buttonload "   >Send Bulk Message</button>

                              </div>
                              <div className="col-md-9 ms-auto my-3">


                              </div>
                            </div>
                          </>)
                      }
                      {
                        value === "2" && (
                          <>
                            <div className="row gy-2 mb-4">
                              <label className="col-sm-3 form-label" htmlFor="inputHorizontalElOne">Upload File</label>
                              <div className="col-sm-9">
                                <input className="form-control" id="inputHorizontalElOne" type="file" name="number" placeholder="Enter Number" onChange={handleFiles} required /><small className="form-text">Upload Your .csv File</small>
                              </div>
                            </div>
                            <div className="row gy-2 mb-4">
                              <label className="col-sm-3 form-label" htmlFor="inputHorizontalElOne">Upload Image</label>
                              <div className="col-sm-9">
                                <input className="form-control" id="inputHorizontalElOne" type="file" name="number" placeholder="Enter Number" accept="image/*" onChange={handleUploadImages} required /><small className="form-text">Upload Your .jpg File</small>
                                <div>

                                  {/* <button onClick={handleImages} className="btn btn-info">upload</button> */}
                                </div>
                              </div>
                            </div>
                            <div className="row gy-2 mb-4">
                              <label className="col-sm-3 form-label" htmlFor="inputHorizontalElTwo">Enter Message</label>
                              <div className="col-sm-9">
                                <textarea className="form-control" name="message" rows="4" cols="50" onChange={(e) => setMessage(e.target.value)} value={message} required></textarea><small className="form-text">Example help text that remains unchanged.</small>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-9 ms-auto">
                                {
                                  multiLoading ? <div className="spinner-border text-success" role="status">
                                    <span className="sr-only">Loading...</span>
                                  </div> :
                                    <button onClick={handleMultipleMediaSms} className="btn buttonload " > Send Bulk Message</button>
                                }
                              </div>
                              <div className="col-md-9 ms-auto my-3">
                              </div>
                            </div>
                          </>)
                      }

                    </form>
                  </div>
                </div>
              </div>

              <div className="col-md-5">
                <h3 className="mb-5"><i className="fas fa-hand-point-right"></i> Single Number</h3>
                <div className=" shadow-0">
                  <div className="card-body p-0">

                    <form className="form-horizontal">
                      <div className="row gy-2 mb-4">
                        <label className="col-sm-3 form-label" htmlFor="inputHorizontalElOne">Choose sms send type</label>
                        <div className="col-sm-9">
                          <select onClick={handleImageValue} className="custom-select custom-select-lg mb-3" >
                            <option defaultValue>Choose sms type</option>
                            <option value="1" >Send only message</option>
                            <option value="2">Send message with image</option>
                          </select>
                        </div>
                      </div>
                      {
                        imageValue === "1" && (
                          <>
                            <div className="row gy-2 mb-4">
                              <label className="col-sm-3 form-label" htmlFor="inputHorizontalElOne">Enter Mobile Number</label>
                              <div className="col-sm-9">
                                <input className="form-control" id="inputHorizontalElOne" type="tel" name="number" onChange={handleMessage} value={inputMessage.number} placeholder="Enter Number" required /><small className="form-text">Example help text that remains unchanged.</small>
                              </div>
                            </div>
                            <div className="row gy-2 mb-4">
                              <label className="col-sm-3 form-label" htmlFor="inputHorizontalElTwo">Enter Message</label>
                              <div className="col-sm-9">
                                <textarea className="form-control" name="message" onChange={handleMessage} value={inputMessage.message} rows="4" cols="50" required></textarea><small className="form-text">Example help text that remains unchanged.</small>
                              </div>

                            </div>
                            <div className="row">
                              <div className="col-md-9 ms-auto">
                                <button onClick={handleSendMessage} className="btn buttonload "  >Send Message</button>
                              </div>

                              <div className="col-md-9 ms-auto my-3">
                              </div>
                            </div>
                          </>
                        )
                      }
                      {
                        imageValue === "2" && (
                          <>
                            <div className="row gy-2 mb-4">
                              <label className="col-sm-3 form-label" htmlFor="inputHorizontalElOne">Enter Mobile Number</label>
                              <div className="col-sm-9">
                                <input className="form-control" id="inputHorizontalElOne" type="tel" name="number" onChange={handleMessage} value={inputMessage.number} placeholder="Enter Number" required /><small className="form-text">Example help text that remains unchanged.</small>
                              </div>
                            </div>
                            <div className="row gy-2 mb-4">
                              <label className="col-sm-3 form-label" htmlFor="inputHorizontalElOne">Upload Image</label>
                              <div className="col-sm-9">
                                <input className="form-control" id="inputHorizontalElOne" type="file" name="number" placeholder="Enter Number" accept="image/*" onChange={handleUploadImages} required /><small className="form-text">Upload Your .jpg File</small>
                                <div>
                                </div>
                              </div>
                            </div>
                            <div className="row gy-2 mb-4">
                              <label className="col-sm-3 form-label" htmlFor="inputHorizontalElTwo">Enter Message</label>
                              <div className="col-sm-9">
                                <textarea className="form-control" name="message" onChange={handleMessage} value={inputMessage.message} rows="4" cols="50" required></textarea><small className="form-text">Example help text that remains unchanged.</small>
                              </div>

                            </div>
                            <div className="row">
                              <div className="col-md-9 ms-auto">
                                {
                                  loading ?
                                    <div className="spinner-border text-success" role="status">
                                      <span className="sr-only">Loading...</span>
                                    </div> :
                                    <button onClick={handleMediaMessage} className="btn buttonload">Send Message</button>
                                }
                              </div>

                              <div className="col-md-9 ms-auto my-3">
                              </div>
                            </div>
                          </>
                        )
                      }


                    </form>
                  </div>
                </div>
              </div>


            </div>
          </div>
        </section>
      </div>
    </div>
  )
}

export default SendBulkMessage