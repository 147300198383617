import React from 'react'
import {NavLink} from 'react-router-dom';
import SideNav from "./SideNav";
import TopNav from "./TopNav";
const HomePage = ({ updateUser }) => {
  return (
    <div>
        <SideNav />

<div className="page">
  <TopNav updateUser={updateUser} />
  <section id="hero">
    <div className="hero-container" data-aos="fade-in">
      <h1>Welcome to Bulk whatsapp</h1>
      <h2>Make your Bulk messag ing eassy  Apps &amp; more...</h2>
      <img src="/img/hero-img.png" alt="Hero Imgs" data-aos="zoom-out" data-aos-delay="100"/>
      <NavLink to="/bulk_sms" className="btn-get-started scrollto">Get Started</NavLink>
    </div>
  </section>

  {/* <main id="main">

    <section id="pricing" className="padd-section text-cente">

      <div className="container" data-aos="fade-up">
        <div className="section-title text-center">

          <h2>Meet With Price</h2>
          <p className="separator">Integer cursus bibendum augue ac cursus .</p>
        </div>

        <div className="row" data-aos="fade-up" data-aos-delay="100">

          <div className="col-md-6 col-lg-4">
            <div className="block-pricing">
              <div className="pricing-table">
                <h4>basic</h4>
                <h2>$29</h2>
                <ul className="list-unstyled">
                  <li><b>4 GB</b> Ram</li>
                  <li><b>7/24</b> Tech Support</li>
                  <li><b>40 GB</b> SSD Cloud Storage</li>
                  <li>Monthly Backups</li>
                  <li>Palo Protection</li>
                </ul>
                <div className="table_btn">
                  <NavLink to="/qr" className="btn"><i className="fas fa-dollar-sign"></i> Buy Now</NavLink>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4">
            <div className="block-pricing">
              <div className="pricing-table">
                <h4>PERSONAL</h4>
                <h2>$29</h2>
                <ul className="list-unstyled">
                  <li><b>4 GB</b> Ram</li>
                  <li><b>7/24</b> Tech Support</li>
                  <li><b>40 GB</b> SSD Cloud Storage</li>
                  <li>Monthly Backups</li>
                  <li>Palo Protection</li>
                </ul>
                <div className="table_btn">
                  <a href="#" className="btn"><i className="fas fa-dollar-sign"></i> Buy Now</a>
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 col-lg-4">
            <div className="block-pricing">
              <div className="pricing-table">
                <h4>BUSINESS</h4>
                <h2>$29</h2>
                <ul className="list-unstyled">
                  <li><b>4 GB</b> Ram</li>
                  <li><b>7/24</b> Tech Support</li>
                  <li><b>40 GB</b> SSD Cloud Storage</li>
                  <li>Monthly Backups</li>
                  <li>Palo Protection</li>
                </ul>
                <div className="table_btn">
                  <a href="#" className="btn"><i className="fas fa-dollar-sign"></i> Buy Now</a>
                </div>
              </div>
            </div>
          </div>

        
        </div>
      </div>
    </section>

    <section id="contact" className="padd-section">

      <div className="container" data-aos="fade-up">
        <div className="section-title text-center">
          <h2>Contact</h2>
          <p className="separator">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque</p>
        </div>

        <div className="row justify-content-center" data-aos="fade-up" data-aos-delay="100">

          <div className="col-lg-3 col-md-4">

            <div className="info">
              <div>
                <i className="bi bi-geo-alt"></i>
                <p>A108 Adam Street<br/>New York, NY 535022</p>
              </div>

              <div className="email">
                <i className="bi bi-envelope"></i>
                <p>info@example.com</p>
              </div>

              <div>
                <i className="bi bi-phone"></i>
                <p>+1 5589 55488 55s</p>
              </div>
            </div>

            <div className="social-links">
              <a href="#" className="twitter"><i className="fab fa-twitter"></i></a>
              <a href="#" className="facebook"><i className="fab fa-facebook-f"></i></a>
              <a href="#" className="instagram"><i className="fab fa-instagram"></i></a>
              <a href="#" className="linkedin"><i className="fab fa-linkedin-in"></i></a>
            </div>

          </div>

          <div className="col-lg-5 col-md-8">
            <div className="form">
              <form  method="post" role="form" className="php-email-form">
                <div className="form-group">
                  <input type="text" name="name" className="form-control" id="name" placeholder="Your Name" required/>
                </div>
                <div className="form-group mt-3">
                  <input type="email" className="form-control" name="email" id="email" placeholder="Your Email" required/>
                </div>
                <div className="form-group mt-3">
                  <input type="text" className="form-control" name="subject" id="subject" placeholder="Subject" required/>
                </div>
                <div className="form-group mt-3">
                  <textarea className="form-control" name="message" rows="5" placeholder="Message" required></textarea>
                </div>
                <div className="my-3">
                  <div className="loading">Loading</div>
                  <div className="error-message"></div>
                  <div className="sent-message">Your message has been sent. Thank you!</div>
                </div>
                <div className="text-center"><button type="submit">Send Message</button></div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main> */}

  <footer className="footer">
    <div className="container">
      <div className="row">

        <div className="col-md-12 col-lg-12">
          <div className="footer-logo">

            <a className="navbar-brand" href="#">Bulk Whatsapp</a>
            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the.</p>

          </div>
        </div>
{/* 
        <div className="col-sm-6 col-md-3 col-lg-2">
          <div className="list-menu">

            <h4>Abou Us</h4>

            <ul className="list-unstyled">
              <li><a href="#">About us</a></li>
              <li><a href="#">Features item</a></li>
              <li><a href="#">Live streaming</a></li>
              <li><a href="#">Privacy Policy</a></li>
            </ul>

          </div>
        </div>

        <div className="col-sm-6 col-md-3 col-lg-2">
          <div className="list-menu">

            <h4>Abou Us</h4>

            <ul className="list-unstyled">
              <li><a href="#">About us</a></li>
              <li><a href="#">Features item</a></li>
              <li><a href="#">Live streaming</a></li>
              <li><a href="#">Privacy Policy</a></li>
            </ul>

          </div>
        </div>

        <div className="col-sm-6 col-md-3 col-lg-2">
          <div className="list-menu">

            <h4>Support</h4>

            <ul className="list-unstyled">
              <li><a href="#">faq</a></li>
              <li><a href="#">Editor help</a></li>
              <li><a href="#">Contact us</a></li>
              <li><a href="#">Privacy Policy</a></li>
            </ul>

          </div>
        </div>

        <div className="col-sm-6 col-md-3 col-lg-2">
          <div className="list-menu">

            <h4>Abou Us</h4>

            <ul className="list-unstyled">
              <li><a href="#">About us</a></li>
              <li><a href="#">Features item</a></li>
              <li><a href="#">Live streaming</a></li>
              <li><a href="#">Privacy Policy</a></li>
            </ul>

          </div>
        </div> */}

      </div>
    </div>

    <div className="copyrights">
      <div className="container">
        <p>&copy; Copyrights Bulk Whatsapp. All rights reserved.</p>
        <div className="credits">
        
          Designed by <a href="">Rechargekit</a>
        </div>
      </div>
    </div>

  </footer>

  <a href="#" className="back-to-top d-flex align-items-center justify-content-center"><i className="bi bi-arrow-up-short"></i></a>
    </div>
    </div>
  )
}

export default HomePage