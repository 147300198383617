import React from "react";
// import axios from 'axios';
import SideNav from "./SideNav";
import TopNav from "./TopNav";
import io from "socket.io-client";
// import 'react-toastify/dist/ReactToastify.css';
// import { ToastContainer, toast } from 'react-toastify';
// import { NavLink } from 'react-router-dom';
// import {useNavigate} from "react-router-dom"
const ENDPOINT = "https://apibulkwhatsapp.redesk.in";
const QrCode = ({ updateUser, message, response }) => {
  const userName = JSON.parse(localStorage.getItem("multiple_whatsapp"));
  // console.log(userName)
  // const navigate=useNavigate();
  // const [inputMessage, setInputMessage] = useState({
  //   number: "",
  //   message: "" 
  // })
  // const handleMessage = (e) => {

  //   const { name, value } = e.target;
  //   setInputMessage({
  //     ...inputMessage, [name]: value
  //   }) 
  // }
  // const handleSendMessage = async (e) => {
  //   e.preventDefault();
  //   const { number, message } = inputMessage;
  //   const res = await fetch('http://localhost:8000/send-message', {
  //     method: "POST",
  //     headers: {
  //       Accept: "application/json",
  //       "Content-Type": "application/json"
  //     },
  //     body: JSON.stringify({ number, message })
  //   })
  //   const data = await res.json();

  //   if (data.status === true) {
  //     toast.success("Message send successfully", {
  //       position: "bottom-right",
  //       theme: "colored",
  //       autoClose: 5000,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //     setInputMessage({
  //       message: ""
  //     })
  //   } else {
  //     toast.error("Message send failed scan again", {
  //       position: "bottom-right",
  //       theme: "colored",
  //       autoClose: 5000,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //     });
  //     setInputMessage({
  //       message: ""
  //     })
  //   }

  // }

  // const [response, setResponse] = useState('');
  //  const [message, setMessage] = useState('');
  //    useEffect(() => {
  //     if (response) {

  //       console.log(response)
  //     } else {
  //       console.log("no response")
  //     }
  //     console.log(message)
  //      const socket = io.connect(ENDPOINT);
  //     socket.on("qr", data => {
  //       // setResponse(data);
  //        console.log(data);
  //       if (data) {
  //         console.log(data)
  //       } else {
  //         console.log("no data available")
  //       }
  //     });
  //     socket.emit("create-session", {
  // id:userName.mobile,description:userName.name
  //     });
  //   // if (message==="whatsapp is ready") {
  //   //   navigate("/bulk_sms");
  //   // }
  //   }, [userName.mobile,userName.name]); 
  //   if (message==="whatsapp is ready") {
  //     navigate("/bulk_sms");

  // }else{
  //     navigate("/")
  // }

  const handleQr = () => {
    const socket = io.connect(ENDPOINT);
    socket.emit("create-session", {
      id: userName.mobile, description: userName.name
    });
    // console.log(socket)
  }
  return (
    <div>
      <SideNav />

      <div className="page">
        <TopNav updateUser={updateUser} />

        <section className="bg-white py-5">
          <div className="container-fluid">
            <div className="row d-flex align-items-md-stretch">

              <div className="col-lg-5">
                <div className=" shadow-0">
                  <div className="card-body p-0">

                    <h2>{message}</h2>
                  </div>
                </div>
              </div>

              <div className="col-lg-3 col-md-6">
                <div className=" shadow-0">

                  <input type="hidden" name="mobile" value={userName.mobile} />
                  <input type="hidden" name="custId" value={userName.name} />
                  <input onClick={handleQr} type="submit" value="Generate QrCode" />
                </div>
              </div>

              <div className="col-md-6">


                <img className={message === "Whatsapp is ready!" ? "d-none" : null} src={response === "" ? "./img/loader.gif" : response} alt="Qrcode" style={{ width: "20rem", marginLeft: "50rem" }} />
                <p style={{ marginLeft: "40rem", width: "100%", fontWeight: "bolder" }}>{response === "" ? "Please wait while we fetch the Qrcode" : "Qr Code Received Plz Scan"}</p>



              </div>
              {/* <button NavLink to="/bulk_sms" className={message==="whatsapp is ready"? "btn btn-primary" : "d-none"}>Enter</button> */}
            </div>
          </div>
        </section>
      </div>
    </div>
  );
};

export default QrCode;
