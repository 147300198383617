import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify';
const Register = () => {
  const [userInputs, setUserInputs] = useState({
    name: "",
    mobile: "",
    email: "",
    password: ""
  })
  const handleInputs = (e) => {
    const { name, value } = e.target;
    setUserInputs({
      ...userInputs, [name]: value
    })
  }
  const handleRegister = async (e) => {
    const { name, mobile, email, password } = userInputs
    e.preventDefault();
    const res = await fetch("https://apibulkwhatsapp.redesk.in/register", {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify({
        name, mobile, email, password
      })
    })

    const data = await res.json();
    if (data.status === "201") {
      toast.success(data.msg, {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,

      });
      setUserInputs({
        name: "",
        mobile: "",
        email: "",
        password: ""
      })
    } else {
      toast.error(data.msg, {
        position: "top-right",
        theme: "colored",
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,

      });
      setUserInputs({
        name: "",
        mobile: "",
        email: "",
        password: ""
      })
    }
  }
  return (
    <div className="login-page d-flex align-items-center bg-gray-100">
      <ToastContainer />
      <div className="container mb-3">
        <div className="row">
          <div className="col-md-6 mx-auto">
            <div className="card">
              <div className="card-body p-5">
                <header className="text-center mb-5">
                  <h1 className="text-xxl text-gray-400 text-uppercase">Bulk<strong className="text-primary">WhatsApp</strong></h1>
                </header>
                <form className="register-form" method="POST">
                  <div className="row">
                    <div className="col-lg-8 mx-auto">
                      <div className="input-material-group mb-3">
                        <input className="input-material" type="text" placeholder='name' name="name" required data-validate-field="name" value={userInputs.name} onChange={handleInputs} />

                      </div>
                      <div className="input-material-group mb-3">
                        <input className="input-material" type="tel" placeholder='mobile' name="mobile" required data-validate-field="mobile" value={userInputs.mobile} onChange={handleInputs} />

                      </div>
                      <div className="input-material-group mb-3">
                        <input className="input-material" type="email" placeholder='Email' name="email" required data-validate-field="email" value={userInputs.email} onChange={handleInputs} />
                      </div>
                      <div className="input-material-group mb-4">
                        <input className="input-material" type="password" placeholder='Password' name="password" required data-validate-field="password" value={userInputs.password} onChange={handleInputs} />
                      </div>
                    </div>
                    <div className="col-12 text-center">
                      <button onClick={handleRegister} className="btn btn-primary mb-3" id="login" type="submit">Register</button><br /><span className="text-xs text-gray-500">Already have an account?  </span><NavLink className="text-xs text-paleBlue ms-1" to="/login">Login</NavLink>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="text-center position-absolute bottom-0 start-0 w-100 z-index-20">
        <p className="text-gray-500">Design by <a className="external" href="#">RechargeKit</a>
        </p>
      </div>
    </div>
  )
}

export default Register