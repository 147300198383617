import React from 'react'

const SideNav = () => {
  const userName = JSON.parse(localStorage.getItem("multiple_whatsapp"));
  return (
    <div>
         <nav className="side-navbar">
    
      <div className="sidebar-header d-flex align-items-center justify-content-center p-3 mb-3">
       
        <div className="sidenav-header-inner text-center"><img className="img-fluid rounded-circle avatar mb-3" src="img/user.jpg" alt="person"/>
          <h2 className="h5 text-white text-uppercase mb-0">{userName.name}</h2>
          <p className="text-sm mb-0 text-white">{userName.mobile}</p>
        </div>
        <a className="brand-small text-center" href="index.html">
          <p className="h1 m-0">BD</p></a>
      </div>
      
    </nav>
    </div>
  )
}

export default SideNav